<template>
  <div class="service-steps" id="service-offset">
    <div class="container">
      <div class="row ">
        <div class="col-md-6 pt-lg-5 mt-lg-5 pad-content">

          <swiper class="swiper" @swiper="onSwiper" @slideChange="onSlideChange" :space-between="20" :autoplay="{
            delay: 3000,
          }" :navigation="{
            enabled: true,
            nextEl: '.myNext-2',
            prevEl: '.myPrev-2',
          }" :pagination="{ type: 'progressbar', }" :speed="1000">
            <div class="index-steps">
              <div :class="[index > 0 ? 'active' : '']" class="step">1</div>
              <div :class="[index > 1 ? 'active' : '']" class="step">2</div>
              <div :class="[index > 2 ? 'active' : '']" class="step">3</div>
              <div :class="[index > 3 ? 'active' : '']" class="step">4</div>
              <div :class="[index > 4 ? 'active' : '']" class="step">5</div>
            </div>
            <swiper-slide>
              <div class="item">
                <h3>
                  {{
                    this.$i18n.locale == 'ar' ?
                      `خطوات طلب الخدمة`
                      :
                      `Steps to request the service`
                  }}
                </h3>
                <h2>
                  {{
                    this.$i18n.locale == 'ar' ?
                      `تحديد الخدمة وتقديم الطلب`
                      :
                      `Select the service and submit the request`
                  }}
                </h2>
                <p>
                  {{
                    this.$i18n.locale == 'ar' ?
                      `يبدأ العميل باختيار الخدمة المطلوبة من قائمة الخدمات المتاحة على النظام، مع تعبئة كافة التفاصيل
                  الضرورية مثل الوصف والشروط الخاصة بالخدمة. بعد ذلك، يتم إرسال الطلب لمراجعة أولية للتأكد من استيفاء
                  كافة المتطلبات.`
                      :
                      `The customer begins by choosing the required service from the list of services available on the
                  system, filling in all the necessary details such as the description and conditions of the service.
                  The application is then sent for an initial review to ensure all requirements are met.`
                  }}
                </p>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="item">
                <h3>
                  {{
                    this.$i18n.locale == 'ar' ?
                      `خطوات طلب الخدمة`
                      :
                      `Steps to request the service`
                  }}
                </h3>
                <h2>
                  {{
                    this.$i18n.locale == 'ar' ?
                      `مراجعة الطلب والتحقق من التفاصيل`
                      :
                      `Review the application and check the details`
                  }}
                </h2>
                <p>
                  {{
                    this.$i18n.locale == 'ar' ?
                      `يتم استلام الطلب من قبل الفريق المختص لمراجعته بعناية، حيث يتم التحقق من دقة المعلومات والتأكد من
                  مطابقة الخدمة المطلوبة للمعايير المحددة. في حال وجود أي نقص أو ملاحظات، يتم التواصل مع العميل لتقديم
                  التعديلات المطلوبة.`
                      :
                      `The request is received by the specialized team to carefully review it, verifying the accuracy of the
                  information and ensuring that the requested service conforms to the specified standards. In the event
                  of any deficiency or comments, the customer will be contacted to provide the required amendments.`
                  }}
                </p>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="item">
                <h3>
                  {{
                    this.$i18n.locale == 'ar' ?
                      `خطوات طلب الخدمة`
                      :
                      `Steps to request the service`
                  }}
                </h3>
                <h2>
                  {{
                    this.$i18n.locale == 'ar' ?
                      `اتخاذ قرار الموافقة أو الرفض`
                      :
                      `Make a decision to approve or reject`
                  }}
                </h2>
                <p>
                  {{
                    this.$i18n.locale == 'ar' ?
                      `بعد إتمام عملية المراجعة، يتم اتخاذ القرار بالموافقة على الطلب أو رفضه. في حال الموافقة، يتم الانتقال
                  إلى مرحلة تسعير الخدمة. أما في حالة الرفض، يتم توضيح أسباب الرفض للعميل وتقديم إرشادات لتعديل الطلب
                  وإعادة تقديمه.`
                      :
                      `After completing the review process, a decision is made to approve or reject the application. If
                  approved, the service pricing stage will proceed. In the event of rejection, the reasons for rejection
                  are explained to the customer and instructions are provided for amending and resubmitting the
                  application.`
                  }}
                </p>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="item">
                <h3>
                  {{
                    this.$i18n.locale == 'ar' ?
                      `خطوات طلب الخدمة`
                      :
                      `Steps to request the service`
                  }}
                </h3>
                <h2>
                  {{
                    this.$i18n.locale == 'ar' ?
                      `إعداد العرض المالي واعتماده من العميل`
                      :
                      `Preparing the financial offer and having it approved by the client`
                  }}
                </h2>
                <p>
                  {{
                    this.$i18n.locale == 'ar' ?
                      `عند الموافقة على الطلب، يتم إعداد العرض المالي الخاص بالخدمة وإرساله للعميل. يتعين على العميل مراجعة
                  العرض والموافقة عليه قبل بدء تنفيذ الخدمة. بمجرد اعتماد العرض، يتم البدء في تنفيذ الإجراءات اللازمة
                  لتقديم الخدمة.`
                      :
                      `When the request is approved, the financial offer for the service is prepared and sent to the
                  customer. The customer must review and agree to the offer before starting the service. Once the offer
                  is approved, the necessary procedures to provide the service begin.`
                  }}
                </p>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="item">
                <h3>
                  {{
                    this.$i18n.locale == 'ar' ?
                      `خطوات طلب الخدمة`
                      :
                      `Steps to request the service`
                  }}
                </h3>
                <h2>
                  {{
                    this.$i18n.locale == 'ar' ?
                      ` تنفيذ الخدمة وإغلاق الطلب`
                      :
                      ` Executing the service and closing the order`
                  }}
                </h2>
                <p>
                  {{
                    this.$i18n.locale == 'ar' ?
                      `بعد إتمام تنفيذ الخدمة، يتم إخطار العميل بإتمام العملية بنجاح. يقوم النظام بتحديث حالة الطلب وإغلاقه
                  بعد التوثيق النهائي. في حال وجود أي استفسارات أو متطلبات إضافية، يتم التعامل معها قبل إتمام الإغلاق
                  لضمان رضا العميل التام.`
                      :
                      `After completing the service implementation, the customer will be notified of the successful
                  completion of the process. The system updates the application status and closes it after final
                  documentation. If there are any additional inquiries or requirements, they will be addressed prior to
                  closing to ensure complete customer satisfaction.`
                  }}
                </p>
              </div>
            </swiper-slide>
          </swiper>
          <div class="d-flex justify-content-start navigation-button ">
            <span class="myPrev myPrev-2">
              <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="36" height="36" rx="5" fill="#052C57"/>
                <path
                  d="M13.6796 17.2929L17.9293 12.9999L16.9395 12L11 18L11.4949 18.4999L16.9395 24L17.9293 23.0001L13.6796 18.7071H25V17.2929H13.6796Z"
                  fill="white"/>
              </svg>
              <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="35.5" y="35.5" width="35" height="35" rx="4.5" transform="rotate(180 35.5 35.5)" fill="white"
                      stroke="#AFAFAF"/>
                <path
                  d="M22.3204 18.7071L18.0707 23.0001L19.0605 24L25 18L24.5051 17.5001L19.0605 12L18.0707 12.9999L22.3204 17.2929L11 17.2929L11 18.7071L22.3204 18.7071Z"
                  fill="#AFAFAF"/>
              </svg>
            </span>
            <span class="myNext myNext-2">
              <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="36" height="36" rx="5" fill="#052C57"/>
                <path
                  d="M13.6796 17.2929L17.9293 12.9999L16.9395 12L11 18L11.4949 18.4999L16.9395 24L17.9293 23.0001L13.6796 18.7071H25V17.2929H13.6796Z"
                  fill="white"/>
              </svg>
              <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="35.5" y="35.5" width="35" height="35" rx="4.5" transform="rotate(180 35.5 35.5)" fill="white"
                      stroke="#AFAFAF"/>
                <path
                  d="M22.3204 18.7071L18.0707 23.0001L19.0605 24L25 18L24.5051 17.5001L19.0605 12L18.0707 12.9999L22.3204 17.2929L11 17.2929L11 18.7071L22.3204 18.7071Z"
                  fill="#AFAFAF"/>
              </svg>
            </span>
            <span class="cta">
                <router-link to="/services">
              <BtnMain
                :title="$t('request_your_service')"
                :TextColor="'#fff'"
                :hovercolor="'#0349A8'"
                :BackgroundColor="'transparent'"
                :hoverBackgroundColor="'#fff'"
                :Border="'#fff'"
                :hoverBorder="'#fff'"/>
            </router-link>
            </span>
          </div>
        </div>
        <div class="col-md-6">
          <img width="100%" height="auto" src="@/assets/img/home-page/service-steps.svg" alt="service steps"
               loading="lazy">
        </div>
      </div>
    </div>
  </div>

</template>
<script>
// Import Swiper Vue.js components
import { defineAsyncComponent } from 'vue'

export default {
  data () {
    return {
      index: 1,
      swiper: null,

    }
  },
  components: {
    BtnMain: defineAsyncComponent(() => import('@/components/Global/BtnMain.vue')),
  },
  methods: {
    onSwiper (swiper) {
      this.swiper = swiper
      this.swiper.autoplay.stop()
    },
    onSlideChange () {
      this.index = this.swiper.activeIndex + 1
    },
    onScroll () {
      const target = document.getElementById('service-offset')
      if (!target) return // Check if target exists

      let offsetTop = target.offsetTop
      if (window.scrollY > (offsetTop - 300)) {
        this.swiper.autoplay.start()
      }
    },
  },
  mounted () {
    // Scroll event listener
    // this.scrollHandler = this.onScroll;
    // window.addEventListener('scroll', this.scrollHandler);
  },
  beforeDestroy () {
    // Clean up the scroll event listener
    window.removeEventListener('scroll', this.scrollHandler)
  },

}
</script>
